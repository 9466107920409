@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/* @import '~font-awesome/css/font-awesome.min.css'; */


body{ font-family: 'Oxygen', sans-serif !important; 
}

h1, h2, h3, h4, h5{ font-family: 'Roboto', sans-serif !important;}

.container-fluid{ max-width: 85%;}

@font-face {
    font-family: 'my-icon-font';
    src:  url('./assets/fonts/icomoon.ttf') format('woff2'),
          url('./assets/fonts/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'my-icon-font' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-dashboard:before {
    content: "\e904";
    color: #787d8c;
  }
  .icon-location:before {
    content: "\e908";
    color: #787d8c;
  }
  /* Add more icons as needed */
  .icon-bell:before {
    content: "\e900";
    color: #787d8c;
  }
  .icon-blogs:before {
    content: "\e901";
    color: #787d8c;
  }
  .icon-calender:before {
    content: "\e902";
    color: #b4b9c8;
  }
  .icon-call:before {
    content: "\e903";
    color: #b4b9c8;
  }
  
  .icon-eye:before {
    content: "\e905";
    color: #787d8c;
  }
  .icon-fax:before {
    content: "\e906";
    color: #b4b9c8;
  }
  .icon-help:before {
    content: "\e907";
    color: #787d8c;
  }
  .icon-location:before {
    content: "\e908";
    color: #787d8c;
  }
  .icon-magnet:before {
    content: "\e909";
    color: #787d8c;
  }
  .icon-message:before {
    content: "\e90a";
    color: #b4b9c8;
  }
  .icon-mls:before {
    content: "\e90b";
    color: #787d8c;
  }
  .icon-packages:before {
    content: "\e90c";
    color: #787d8c;
  }
  .icon-pencil:before {
    content: "\e90d";
    color: #505564;
  }
  .icon-search:before {
    content: "\e90e";
    color: #505564;
  }
  .icon-Setting:before {
    content: "\e90f";
    color: #787d8c;
  }
  .icon-traffic:before {
    content: "\e910";
    color: #787d8c;
  }
  .icon-users:before {
    content: "\e911";
    color: #787d8c;
  }
  .icon-vector0:before {
    content: "\e912";
    color: #fff;
  }
  .icon-Vector1:before {
    content: "\e913";
    color: #fff;
  }
  .icon-Vector2:before {
    content: "\e914";
    color: #fff;
  }
  .icon-vector4:before {
    content: "\e915";
    color: #fff;
  }





  .icon-upper-arrow:before {
    content: "\e916";
    color: #787d8c; transform: rotate(360deg);
  }
  .icon-Magnifier:before {
    content: "\e917";
    color: #787d8c;
  }
  .icon-tag:before {
    content: "\e918";
    color: #787d8c;
  }
  .icon-image:before {
    content: "\e919";
    color: #787d8c;
  }
  .icon-show-chart:before {
    content: "\e91a";
    color: #787d8c;
  }
  .icon-google:before {
    content: "\e91b";
    color: #787d8c;
  }
  .icon-Arrow:before {
    content: "\e91c";
    color: #787d8c;
  }


  .icon-Close:before {
    content: "\e91d";
    color: #b4b9c8;
  }
  .icon-map:before {
    content: "\e91e";
    color: #b4b9c8;
  }

  .icon-Pencil:before {
    content: "\e91f";
    color: #b4b9c8;
  }

  .icon-tag1:before {
    content: "\e920";
    color: #787d8c;
  }
  .icon-document:before {
    content: "\e921";
    color: #787d8c;
  }
  .icon-grid:before {
    content: "\e922";
    color: #787d8c;
  }

  .icon-Pencil1:before {
    content: "\e923";
    color: #ff9600;
  }
  .icon-Recycle-Bin:before {
    content: "\e924";
    color: #ed4949;
  }
  .icon-eye1:before {
    content: "\e925";
    color: #28ac47;
  }


/*   html, body {
    height: 100%;
    margin: 0;
    padding: 0;
} */




  .option-icon{ font-size: 21px;}
  .special-class{ border-bottom: 1px solid #3C4150;}
  .user_set {
    background: none !important;
    border: none !important;
}

.main_container{ max-width: 100%;}



.form-control:focus {
  color:none;
  background-color: none;
  border-color: #F0F5FF !important;
  outline: 0;
  box-shadow: none !important;
}
