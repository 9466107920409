
span.icon-upper-arrow {
  position: relative;
  right: -50px;
  top: 4px;
}


.icon-upper-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}


.icon-upper-arrow.down {
  transform: rotate(0deg) !important; 
}




.sidebar {
  width: 230px;
  height: 100%;
  background-color: #141928;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease;
}
  .sidebar.collapsed {
    width: 50px;
  }
  
  .logo {
    font-size: 24px;
    padding: 20px 0;
    font-weight: bold;
  }
  
  .options {
    list-style-type: none;
    padding: 0;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    color: var(--color-tertiary-grey-light, #B4B9C8) !important;
   
}
.middle_sidebar {
    width: 77%;
    margin-left: 50px;
}
  
  .option {
    width: 100%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;  margin-top: 4px;
  }
  
  .option:hover {
    background-color: #2F4F4F;
  }
  
  .option-icon {
    margin-right: 10px;
  }
  

  
  .collapse-btn:focus {
    outline: none;
  }
  
  
  .dashboard {
    padding: 20px;
  }
  
  

  .dashboard-container{ display: flex;}
  .header-dasboard{ width: 100%;}




  .header {
    background-color: #141928;
    color: #FFFFFF;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header-title {
    margin: 0;
    font-size: 24px;
  }
  
  .header-nav {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 15px;
  }
  
  .header-nav-item {
    cursor: pointer;
  }
  
  .header-user {
    cursor: pointer;
  }
  
  

  a.option-link {
     text-decoration: none; color: var(--color-tertiary-grey-light, #B4B9C8) !important;
   
  
    font-size: 15px !important;
  
    font-weight: 400;
    line-height: 150%; 
}

.logo-image-small{ width: 30px;}




.option-link,
.option-link:visited {
  color: inherit; /* Your desired color */
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  
}
.option:hover,
.option.active {
  background-color: #3C4150;
  color: #fff; 
  -webkit-border-top-left-radius: 10px;
-webkit-border-bottom-left-radius: 10px;
-moz-border-radius-topleft: 10px;
-moz-border-radius-bottomleft: 10px;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
}



.arrow-icon {
    width: 24px;
    height: auto;
    transition: transform 0.3s ease-in-out;
    margin-right: 14px;
}
  
  .collapse-btn.collapsed .arrow-icon {
    transform: rotate(180deg);
  }
  .btn-custom {
    background: none;
    border: none;
    color: #fff;
    color: var(--color-tertiary-grey-mid, #787D8C);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0px;
/*     position: ; */
    bottom: 20px; 
    
   
    
}

.option.border-bottom {
    border-bottom: 1px solid #3C4150 !important;
    margin-bottom: 8px; 
    padding-bottom: 8px; 
}

/* .sidebar-main {
 
  height: 100vh;
} */


.options .collapsed{width: 100%;
    margin-left: 0px;}


  /*   header */

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    padding-right: 0px;
}
  
  .left-section,
  .right-section {
    display: flex;
    align-items: center;
  }
  
  .left-section p,
  .left-section a,
  .right-section div,
  .right-section svg {
    margin-right: 10px;
  }
  
  .search-box {
    position: relative;
  }
  
  .search-box input {
    padding-right: 30px;
    border-radius: 5px;
    border: 0.5px solid var(--color-secondary-dark-light, #505564);
    height: 35px;
    background: none;
    color: #fff;
    color: var(--color-tertiary-grey-light, #B4B9C8);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    width: 211px;
    padding-left: 15px;
    padding-right: 38px;
}
  
  .search-box svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .user-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  .left-section p{ margin-top: 20px; color: var(--color-white-pure, #FFF);
    
    font-size: 14px;
   
    font-weight: 400;
    line-height: 150%; }
  .left-section{ gap: 15px;}
  .icon-pencil, .icon-eye, .icon-search, .icon-bell{ font-size:22px !important; }
  .left-section a{ text-decoration: none;}

  span.icon-eye {
   
    position: relative;
    top: 0px;
}

/* .left-section input[type="text"] {
    background: #141928; border-bottom: 1px solid #B4B9C8; color: #fff; border-top: 0px; border-left: 0px; border-right: 0px; font-size: 14px;
} */
.left-section input[type="text"] {
  background: #141928;
  border-bottom: none;
  color: #fff;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 14px;
  color: #fff !important; width: 184px ;
}
.left-section input[type="text"]::placeholder{ color: #fff;}


.right-section {
    gap: 15px;
}
.search-box {
    position: relative;
}
span.icon-search {
    position: absolute;
    right: 11px;
    top: 7px;
}
.bell_notification {
    margin-top: 6px; position: relative;
}


span.notification_count {
    position: absolute;
    top: -6px;
    background: red;
    height: 14px;
    width: 14px;
    border-radius: 100%;
    padding: 0px;
    font-size: 9px;
    left: 14px !important;
    text-align: center;
}



/* dashboard */

.block {
  border: 1px solid #ddd;
  border-radius: 0 20px 0 0;
  position: relative;
  margin-bottom: 20px;
}
.icon-bg {
  background-color: #4CAF50; /* Green */
  border-radius:100%;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
.icon {
  color: white;
}
.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%; 
  height: 50px; 
}
.number {
  font-size: 24px;
  margin: 60px 0 10px;
}




.block {
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
  padding: 0px 14px !important;
}

.icon-bg {
  background-color: #4CAF50;
  border-radius: 100%;
  padding: 8px;
  position: absolute;
  top: 18px;
  left: 14px;
  font-size: 27px;
}

.icon-vector4, .icon-vector0, .icon-Vector1, .icon-Vector2 {
  /* Include your styling or path to the icons here */
}

.chart-space {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50px;
}

.number {
  color: var(--color-secondary-dark, #141928);
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 70px;
}

.block-color1 .block  { background: #CCE4FF;}
.block-color2 .block{background: var(--pastel-light-orange, #FFEACC);}
.block-color3 .block{ background: var(--pastel-light-green, #D4EEDA);}
.block-color4 .block { background: var(--pastel-light-red, #FBDBDB);}
.block-color1 .icon-bg  { background: var(--pastel-dark-blue, #007AFF);}
.block-color2 .icon-bg {background: var(--pastel-dark-orange, #FF9600);}
.block-color3 .icon-bg { background: var(--pastel-dark-green, #28AC47);}
.block-color4 .icon-bg  { background: var(--pastel-dark-red, #ED4949);}


.blockp {
  color: var(--color-secondary-dark-light, #505564);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: -9px !important;
}

.chart-space {
  position: absolute;
  top: 22px;
  right: 9px;
  width: 50%;
  height: 50px;
}

.dashboard {
  padding-left: 21px;
  padding-top: 30px;
}

.mutual_blocks{ border-radius: 10px;
  background: var(--color-white-pure, #FFF);
  box-shadow: 0px 0px 30px 0px rgba(80, 143, 244, 0.10); padding: 30px;}
  .tick-label {
    color: #B4B9C8;
    text-align: center;
    font-family: Oxygen, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
.heading_inline{ justify-content: space-between; margin-bottom: 15px;}

.left_head h2{ color: var(--color-secondary-dark-mid, #3C4150);
  
 
  font-size: 20px;
  
  font-weight: 700;
  }

  .details-link{ color: var(--color-tertiary-grey, #646978);
    text-align: right;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    text-decoration-line: underline;}

    .mean_median{ justify-content: end; gap: 20px; margin-bottom: 50px;}

    .left_mean, .right_mean { color: var(--color-tertiary-grey-mid, #787D8C);
      
      font-size: 14px;
    
      font-weight: 400;
      line-height: 150%; }

      .left_mean span {
        background: #22A9E0;
        border: 0px solid #22A9E0;
        padding: 0px 19px;
        font-size: 3px !important;
        margin-right: 10px;
        margin-top: -5px;
        position: relative;
        top: -4px;
    }

    .right_mean span {
      background: #007AFF;
      border: 0px solid #007AFF;
      padding: 0px 19px;
      font-size: 3px !important;
      margin-right: 10px;
      margin-top: -5px;
      position: relative;
      top: -4px;
  }
  .teammate {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid #B4B9C8;
    padding-bottom: 23px;
}
  
  .teammate-img {
    border-radius: 50%;
    width: 54px;
    height: 54px;
  }
  
  .teammate-info {
    margin-left: 12px;
  }
  
  .teammate-info h3 {
    color: var(--color-tertiary-grey-mid, #787D8C);
    font-size: 16px;
    font-weight: 400;
    line-height: 4px;
    margin-top: 12px;
}
  
  .sales-link {
    color: var(--color-tertiary-grey-light, #B4B9C8);

font-size: 12px;

font-weight: 400;
line-height: 150%; 
text-decoration-line: underline;
  }
  

  .team_mamet{ padding: 20px;}

  .heading_inline{ margin-bottom: 30px;}

  .icon_mut {
    font-size: 22px !important;
   
    position: relative;
    top: 2px;
}


.icon_text_area {
  margin-top: 28px;
}

  .borrom_blocks h3{ color: var(--color-tertiary-grey-mid, #787D8C); margin-bottom: 20px;
  
    font-size: 16px;
   
    font-weight: 700;
    }
  .borrom_blocks p{ color: var(--color-tertiary-grey, #646978);
   
  
    font-size: 16px;
   
    font-weight: 400;
    line-height: 150%; }
    .top_set{ margin-top: -12px;}

    .text_set {
      color: var(--color-tertiary-grey, #646978);
      font-size: 14px !important;
      font-weight: 400;
      line-height: 150%;
      margin-left: 10px;
  }

      .iconing{ margin-top: 15px;}

      .header{ padding-right: 20px;}


      .single_social a {
        border-radius: 4px;
        border: 1px solid var(--color-secondary-dark-light, #505564);
        background: var(--color-secondary-dark-light, #505564);
        height: 40px;
        width: 40px;
        color: #fff;
        font-size: 18px;
        text-align: center;
        padding: 6px; display: inline-block;
    }
    .share_links.d-flex {
      gap: 8px;
  }

  .social_area p{ color: var(--color-tertiary-grey, #646978);

   
  
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; }

    .followme{ margin-top: 54px;}

    .followme h4{ color: var(--color-tertiary-grey-mid, #787D8C);

   margin-bottom: 30px;
      font-size: 16px;
    
      font-weight: 700;
     }
    

     .teammate:last-child{
      border-bottom: none;
   }




   .blog_only .option-icon { margin-left: -15px;
    
}










   

   @media (min-width: 1500px) and (max-width:1800px) {
    .wid_adjust {
      width: 78% !important;

    }
    .wid_set {
      width: 22% !important;
  }
  .blockp {
   
    font-size: 15px;
    
}
.number {
 
  font-size: 22px;
 
}
.teammate-info h3 {

font-size: 15px;

}
.borrom_blocks p {

font-size: 15px;

}
.social_area p {

font-size: 15px;

}
.option-icon {
  font-size: 20px;
}
a.option-link{ font-size: 14.5px !important;}


   }


    @media (min-width: 1366px) and (max-width:1499px) {
      a.option-link{ font-size: 14.5px !important;}

      .wid_adjust {
        width: 78% !important;

      }
      .wid_set {
        width: 22% !important;
    }
    .blockp {
     
      font-size: 15px;
      
  }
  .number {
   
    font-size: 22px;
   
}
.teammate-info h3 {
 
  font-size: 15px;
  
}
.borrom_blocks p {
 
  font-size: 15px;
 
}
.social_area p {
  
  font-size: 15px;

}
.option-icon {
  font-size: 20px;
}

    }


    @media (min-width: 1280px) and (max-width:1365px) {
      .chart-space {
        position: absolute;
        top: 14px;
        right: 16px;
       
    }
    .number {
      font-size: 20px !important;
  }

.icon-bg{ font-size: 22px !important;}



      .wid_adjust {
        width: 76% !important;

      }
      .wid_set {
        width: 24% !important;
    }
    .blockp {
     
      font-size: 15px;
      
  }
  .number {
   
    font-size: 22px;
   
}
.teammate-info h3 {
 
  font-size: 15px;
  
}
.borrom_blocks p {
 
  font-size: 15px;
 
}
.social_area p {
  
  font-size: 15px;

}
a.option-link{ font-size: 14.5px !important;}

    }

   
 
  
  /* Mobile styles */
  .mobile_only {
    display: none;
  }
  
 


  .toggle-button {
    display: none;
  }





  @media (min-width: 992px) and (max-width:1279px) {

    .wid_adjust{ width: 100% !important;}
    .wid_set{ width: 100% !important; margin-top: 10px !important;}

  }



  @media (min-width:768px) and (max-width:1279px) {




.sidebar {
    width: 169px !important;
   
}
.middle_sidebar {
  width: 87% !important;
  margin-left: 22px !important;
}
.number{ font-size: 19px !important;}
.blockp{ font-size: 14px !important;}   
.borrom_blocks{ margin-bottom: 10px;}


  }
  

 
  @media (min-width: 481px) and (max-width:767px) {
.wid_adjust .col-lg-3{ width: 49%;}


    .right-section {
      width: 100%;
      margin-top: 20px;
      justify-content: flex-end;
  }
    .left-section p {
     
      font-size: 12px;
    
  }
    .mobile_only {
      display: block;
    }
    .sidebar {
      display: none;
    }
    .sidebar.visible {
      display: block;
    }
    .toggle-button {
      display: inline-block;
      /* your styling for button */
    }

    button.toggle-button {
      position: absolute;
      top: 16px;
      left: 11px;
  }
  button.collapse-btn.btn-custom {
    display: none;
}
.dashboard{ padding-left: 0px; padding-right: 0px;}

.right-section {
  width: 100%;
  margin-top: 20px;
}
.left-section {
  gap: 7px;
  width: 100%;
  margin-top: -9px;
  justify-content: flex-end;
}
.header{ flex-wrap: wrap;}
.icon-bg {
 
  padding: 8px;

  top: 14px;
  left: 10px;
  font-size: 19px;
}
.number {
 
  font-size: 18px;
  
}
.blockp {
 
  font-size: 14px;
  
}
.block {
  
  margin-bottom: 9px;
  
}
.left_head h2 {
 
  font-size: 17px;
 
}
.left_mean, .right_mean {
 
  font-size: 11px;

}
.mutual_blocks {
  
  padding: 13px;
}
.left_mean span {
 
  padding: 0px 6px;
  
}
.right_mean span {

  padding: 0px 6px;
  
}
.team_mamet {
 
  margin-top: 20px;
}
.mutual_blocks {
  padding: 13px;
  margin-top: 20px;
}

.borrom_blocks p, .social_area p{ font-size: 14px;}
.single_social a {
 
  height: 35px;
  width: 35px;
  
  font-size: 15px;
 
}
.search-box input {
  width: 100%;
}
.header {
  padding-right: 0px; padding-left: 0px;
}

  } 
 
  @media (min-width: 320px) and (max-width:480px) {
    .right-section {
      width: 100%;
      margin-top: 20px;
      justify-content: flex-end;
  }
    .search-box input {
      width: 158px;
  }
    .header {
      padding-right: 0px; padding-left: 13px;
    }
    .left-section p {
     
      font-size: 12px;
    
  }
    .mobile_only {
      display: block;
    }
    .sidebar {
      display: none;
    }
    .sidebar.visible {
      display: block;
    }
    .toggle-button {
      display: inline-block;
      /* your styling for button */
    }

    button.toggle-button {
      position: absolute;
      top: 16px;
      left: 11px;
  }
  button.collapse-btn.btn-custom {
    display: none;
}
.dashboard{ padding-left: 0px; padding-right: 0px;}

.right-section {
  width: 100%;
  margin-top: 20px;
}
.left-section {
  gap: 7px;
  width: 100%;
  margin-top: -9px;
  justify-content: flex-end;
}
.header{ flex-wrap: wrap;}
.icon-bg {
 
  padding: 8px;

  top: 14px;
  left: 10px;
  font-size: 19px;
}
.number {
 
  font-size: 18px;
  
}
.blockp {
 
  font-size: 14px;
  
}
.block {
  
  margin-bottom: 9px;
  
}
.left_head h2 {
 
  font-size: 17px;
 
}
.left_mean, .right_mean {
 
  font-size: 11px;

}
.mutual_blocks {
  
  padding: 13px;
}
.left_mean span {
 
  padding: 0px 6px;
  
}
.right_mean span {

  padding: 0px 6px;
  
}
.team_mamet {
 
  margin-top: 20px;
}
.mutual_blocks {
  padding: 13px;
  margin-top: 20px;
}

.borrom_blocks p, .social_area p{ font-size: 14px;}
.single_social a {
 
  height: 35px;
  width: 35px;
  
  font-size: 15px;
 
}

  }
 
   

  .main-logo{ text-align: center;}
  .sidebar-main {
    position: relative;
   
}



/* Sidebar.css */


.setting_only{ display: inherit;}

ul.sub-options {
  margin: 0px;
  padding: 0px;
}
li.sub-option {
  list-style-type: none;
  text-align: left; 
}

.sub-options a{     text-decoration: none;
  color: var(--color-tertiary-grey-light, #B4B9C8) !important;
  font-size: 15px !important;
  font-weight: 400;
  line-height: 150%;}

 
  .icon-Setting {
    margin-left: -3px;
}

.setting_only a.option-link.active {
  margin-top: -7px;
}

li.sub-option {
  list-style-type: none;
  text-align: left;
  line-height: 39px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  margin: 10px;
  margin-left: 0px;
  margin-top: 17px;
}

.setting_only span{ font-size: 21px !important;}






svg.svg-inline--fa.fa-chevron-down.icon-chevron-down.rotated {
  text-align: revert;
  margin-left: 58px;
}

.icon-chevron-down.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out; margin-left: 58px !important;font-size: 12px; color: #787d8c;
}
svg.svg-inline--fa.fa-chevron-down.icon-chevron-down { font-size: 12px;color: #787d8c;
  margin-left: 58px; 
}


.sub-options li:hover{ }