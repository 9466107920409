.teammatess {
    width: 32%;
    border-radius: 5px;
    border: 1px solid var(--color-white-blue, #F0F5FF);
    padding: 35px;
    display: flex;
    margin-top: 40px;
}
.team-mate-page-only{ flex-wrap: wrap;}
.team-mate-page-only {
    flex-wrap: wrap;
    justify-content: space-between;
}

.team-mate-page-only .teammate-info h3{ color: var(--color-tertiary-grey-mid, #787D8C);
   
    font-family: Oxygen;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;}
.team-mate-page-only .sales-link{ color: var(--color-tertiary-grey-light, #B4B9C8);
   
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    text-decoration-line: underline;}
.team-mate-page-only .teammate-img{ height: 80px; width: 80px;}

.team-matew-page{ margin-top: 20px; margin-bottom: -10px;}
.team-matew-page h2{ color: var(--color-secondary-dark-mid, #3C4150);

  
    font-size: 24px;
   
    font-weight: 700;
    }
.team-matew-page .right_head{ color: var(--color-tertiary-grey-mid, #787D8C);

  
    font-family: Roboto;
    font-size: 16px;
  
    font-weight: 700;
}
.custom-pagination{ text-align: center; margin-top: 30px; margin-bottom: 20px;}
.custom-pagination ul{ margin: 0px; padding: 0px; }
.custom-pagination ul li{ display: inline; color: var(--color-tertiary-grey-light, #B4B9C8);
    text-align: center;
   font-family: Roboto;
    font-size: 16px;
      font-weight: 700;
    line-height: 28px; padding: 0px 25px; cursor: pointer;}

.rotate_img{ rotate: 180deg;}

.active-page{ color: #fff; border-radius: 41px;
    background: var(--color-secondary-dark, #141928); height: 32px; padding: 10px 22px !important;}



    .results_realtor{ margin-top: 50px;}
    .realtor_right .update_btn{ padding: 12px 30px !important;}

    .real_left, .real_right{ width: 48%;}
    .real_row{ justify-content: space-between; flex-wrap: wrap;}

    .realtor_modal .modal-body{ padding: 32px !important; padding-top: 20px !important;}

    .real_row {
      
        margin-bottom: 10px;
    }

    .real_full_wifth{ margin-top: 20px;}

    .raltor_btns .raltor_btnss{ padding: 13px 65px !important; width: 50%;}

    .add_realtor_message {
        text-align: center;
        border:none;
        padding-bottom: 0px;
        border-radius: 5px;
        position: relative;
    }
    .cross_btn {
        position: absolute;
        right: -40px;
        top: -40px; cursor: pointer;
    }
    .add_realtor_message h3::after {
        position: absolute;
        content: '';
        background: #141928;
        height: 2px;
        width: 170px;
        top: 30px;
        left: 101px;
        text-align: center;
        margin: 0 auto;
    }

    .add_realtor_message h4 { 
        font-family: 'Oxygen', sans-serif !important;
        color: var(--color-tertiary-grey-mid, #787D8C);
        text-align: center;
        
        /* Body Small */
        font-family: Oxygen;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }

    .two_icons{ gap: 10px;}
    .teammatess .teammate-info{ padding-left: 10px;}

    .hidden {
       opacity: 0.5;
    }
    
    .teammatess .single_icons img[alt="toggle visibility"] {
        /* Styles for the eye icon when visible */
    }
    
    .teammatess .hidden .single_icons img[alt="toggle visibility"] {
        /* Styles for the eye icon when hidden, for example, a line over it */
        position: relative;
    }
    
    .teammatess .hidden .single_icons img[alt="toggle visibility"]::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 2px;
        background: black; /* Or any color you want for the line */
        transform: translateY(-50%);
    }

    .add_multiple_realt{ color: var(--color-primary-blue, #22A9E0) !important;
        font-family: 'Oxygen', sans-serif !important; 
        font-size: 14px;
       
        font-weight: 700;
        line-height: 150%; 
        text-decoration-line: underline; margin-top: 10px; border: none; background: none;}

        .slides_arrows{ display: flex; justify-content: space-between; }
        
       

        .slider_serting{ position: relative;}
        .next_aro img{ transform: rotate(180deg);}

        .previous_aro {
            position: absolute;
            top: 40%;
            left: -48px;
        }
        .next_aro {
            position: absolute;
            top: 40%;
            right: -48px;
        }

        .dot_list{ height: 8px; width: 8px; background: #B4B9C8; border-radius: 500px; }

        .dot_list.active {
           background: #22A9E0;
        }
        .dotting {
           width: 100%;
            margin-bottom: 10px;
            display: inline-flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
        }

        .slides {
            display: none;
        }
        
        .slides.active {
            display: block;
        }



        .slades {
            overflow: hidden;
            position: relative;
        }
        
        .slides-container {
            display: flex;
            transition: transform 0.3s ease-in-out; /* Adjust timing as needed */
        }
        
        .slides {
            flex: 0 0 100%; /* Each slide takes 100% of the slider width */
            max-width: 100%;
        }
        
    


    @media (min-width: 768px) and (max-width:1279px) {
        .teammatess {
            width: 49%;
           
            padding: 11px;
           
            margin-top: 15px;
        }
        .heading_inline.d-flex.team-matew-page {
            margin-top: 16px;
            margin-bottom: 10px;
        }
        .team-mate-page-only .teammate-info h3{ font-size: 16px;}
        .custom-pagination ul li {
            
            font-size: 14px;
           
            padding: 0px 12px;
            
        }
        
    }



    @media (min-width: 320px) and (max-width:767px) {
        .team-matew-page .right_head {
           
          
            font-size: 14px;
            
        }

        .teammatess {
            width: 100%;
           
            padding: 7px;
           
            margin-top: 14px;
        }
        .heading_inline.d-flex.team-matew-page {
            margin-top: 16px;
            margin-bottom: 10px;
        }
        .team-mate-page-only .teammate-info h3{ font-size: 16px;}
        .custom-pagination ul li {
            
            font-size: 14px;
           
            padding: 0px 12px;
            
        }

    }