.amp-pages h2{ font-weight: 700 !important;}
.amp-pages p{ color: var(--color-secondary-dark-mid, #3C4150);
  font-size: 15px;
  font-weight: 400;
  line-height: 150%; margin-top: 25px;}


.amp-pages h3{
  color: var(--color-secondary-dark-mid, #3C4150);


font-size: 20px;

font-weight: 700; margin-top: 50px;

}


.title_bar_setting  label{ margin-top: 23px; color: var(--color-secondary-dark-mid, #3C4150);

  
  font-size: 15px;
 
  font-weight: 400;
  }

.title_bar_setting .form-control{ color: var(--color-tertiary-grey-mid, #787D8C);

  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid var(--color-white-blue, #F0F5FF);
  background: var(--color-white-pure, #FFF);
  box-shadow: 0px 0px 20px 0px rgba(225, 225, 225, 0.25); height: 42px; margin-top: 10px;}


  .small_text{ color: var(--color-secondary-dark-mid, #3C4150);

    
    font-size: 12px;
  
    font-weight: 400;
    line-height: 150%; margin-top: 15px;}
    .second_input{ margin-top: 25px;}

    .two_btns{ margin-top: 45px;}

    .update_btn{ border-radius: 4px !important;
      background: #22A9E0 !important;
      box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);  color: var(--color-white-pure, #FFF) !important;
      text-align: center !important;
   
      font-size: 16px !important;
     padding: 14px 30px !important;
      font-weight: 500 !important;
      line-height: 16px !important; }
.cancel_btn{ border-radius: 4px !important;
  background: #fff !important;
  border: 1px solid #22A9E0 !important;
  box-shadow: 0px 0px 10px 0px rgba(182, 182, 182, 0.50);
  text-align: center !important;
color: #22A9E0 !important;
  font-size: 16px !important;
 padding: 14px 30px !important;
  font-weight: 500 !important;
  line-height: 16px !important; margin-left: 15px;}


.ga a{ color: #000;}
.area_control{ height: inherit !important; min-height: 170px !important;}
.ga textarea{ margin-top: 15px !important;}
.smaller_text{ color: var(--color-tertiary-grey-mid, #787D8C);

  font-size: 14px;
 
  font-weight: 400;
  line-height: 150%;}
  .second_input label{ margin-top: 0px;}


  .center_border {
    border-bottom: 1px solid #B4B9C8;
    margin-top: 45px;
    margin-bottom: -11px;
}

.custom_fcontrol{ justify-content: space-between; align-items: center; margin-top: 8px;}
.left_farea{width: 69%;}
.right_farea{ width: 29%; color: var(--color-tertiary-grey, #646978);

 
  font-size: 14px;
 
  font-weight: 400;
  line-height: 150%; }

  .mtags{}

  .sub-checkbox-container {
    margin-left: 32px;
    margin-top: -10px;
}
.round_checks p{ margin-top: 40px; color: var(--color-secondary-dark-mid, #3C4150) !important;

 
  font-size: 16px !important;
  
  font-weight: 400;
  line-height: 150%;}


  .ldisplay label{ color: var(--color-tertiary-grey, #646978);

   
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    }

    .round_checks label{ color: var(--color-secondary-dark-mid, #3C4150) !important;

      font-size: 16px !important;
      
      font-weight: 400;
      }

      .round_checks .checkmark {
        position: relative;
        height: 22px;
        width: 22px;
        background-color: #fff;
        border-radius: 100px;
        border: 2px solid #22A9E0;
        margin-right: 10px;
    }

    .round_checks .custom-checkbox-container .checkmark:after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
  }


  .new_badge_list input.form-control {
    width: 50px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -20px;
}
.new_badge_list{ margin-top: 20px; margin-bottom: -30px;}


 .labelling{ color: var(--color-secondary-dark-mid, #3C4150) !important;

  /* Body Normal */
  
  font-size: 16px !important;
  
  font-weight: 400; margin-top: 0px !important;
  }


  .search-custom {
    margin-top: 23px !important;
}


.search_page select{ color: var(--color-tertiary-grey, #646978);
  border-radius: 5px;
  border: 1px solid var(--color-white-blue, #F0F5FF); height: 41px !important; min-width: 282px;
 
  font-size: 14px;
  
  font-weight: 400;
  }






  .lfselect {
    width: 41%;
}
.rfselect{  width: 60% !important;}

.lfselect select{ width: 282px;}



.lfselect .labelling {
  color: var(--color-secondary-dark-mid, #3C4150) !important;
  font-size: 16px !important;
  font-weight: 400;
  margin-top: 15px !important;
  margin-bottom: 10px;
}
.bottom_select_custom{ margin-top: 30px;}
.jus_set{ justify-content: space-between;}

.mt-top{ margin-top: 20px !important;}








.web_logo{ position: relative;}
.closer {
  position: absolute;
  top: 0px;
  right: 1px;
}
.closer img{height: 22px !important; width: 22px !important;}

.web_logo {
  border: 1px dashed var(--color-tertiary-grey-light, #B4B9C8);
  width: 261px;
  height: 139px;
  
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.web_logo img {
  width: 90%;
  height: 90%; 
  object-fit: contain; 
  object-position: center; 
}




.logo_uploader{ margin-left: 100px;}



  .logo_uploader img{}


  .logo_uploader p{ color: var(--color-secondary-dark-light, #505564);
    text-align: center; margin-top: 10px;
    
   
  
    font-size: 14px;

    font-weight: 400;
    line-height: 150%;}


    .logo_uploader {
      border: 1px dashed var(--color-primary-blue, #22A9E0);
      width: 261px;
      height: 139px;
      text-align: center;
      padding-top: 30px; margin-bottom: 20px;
  }

  .logo-uploader{ margin-top: 40px; margin-left: 20px;}
  .brand_alt label{ color: var(--color-secondary-dark-light, #505564) !important;

   
    font-size: 14px !important;
   
    font-weight: 400;
    }

    .brand_alt .form-control{ min-width: 240px;}
   .brand_alt {
      margin-left: 10px;
  }



  .logo_uploader label {
    margin-top: 0px;
    color: var(--color-secondary-dark-mid, #3C4150);
    font-size: 15px;
    font-weight: 400; cursor: pointer;
}



.mtset{ margin-top: 15px !important;}
.logo_link_to {
  margin-bottom: -3px !important;
  margin-top: 22px !important; margin-left: 15px;
}
.company_links .allchecks{ margin-left: 15px;}

.company_links label{ color: var(--color-secondary-dark-light, #505564) !important;

 
  font-size: 14px !important;

  font-weight: 400;
 }

 .company_links .form-control{ width: 240px; margin-left: 15px;}

 .last_check {
  margin-top: 8px !important;
}

.company_links .form-control {
  width: 240px;
  margin-left: 15px;
  margin-top: 8px;
}

.favicon_uploader{  border: 1px dashed var(--color-primary-blue, #22A9E0);
  width: 199px;
  height: 106px;
  text-align: center;
   margin-bottom: 20px;}
  .favicon_uploader img{ width: 49px; cursor: pointer;}
  .favicon_uploader p{ margin-top: 6px; cursor: pointer;}


.left_link_comp{ width: 60%; position: relative;}
.right_link_comp{ width: 40%;}

.left_link_comp::after {
  background: #B4B9C8;
  content: '';
  position: absolute;
  height: 240px;
  width: 1px;
  top: 55px;
  right: 94px;
}
.favicon_uploader {
  position: relative;
  text-align: center;
  padding-top: 9px; cursor: pointer;
}

.close_fav {
  position: absolute;
  top: -3px;
  right: 2px;
  cursor: pointer;
}
.close_fav img {
  height: 18px !important;
  width: 18px !important; cursor: pointer;
}

.favicon_image {
  width: 90% !important;
  height: 90% !important;
  object-fit: contain;
  
}

.favicon_label {
  display: inline-block;
  width: 100%;
  height: 100%;
  
}

.favicon_input {
  display: none;
  
}
.favicon_uploader{ margin-top: 25px;}
.favicon_uploader label{ margin-top: 11px;}
.width-controlls {
  width: 282px !important;
  margin-top: -1px !important;
  margin-left: 10px;
}
.url_linking{ color: var(--color-primary-blue, #22A9E0);

  
  font-size: 14px;
 
  font-weight: 400;
   }

   .second_mtdis{ margin-top: 50px;}





 /*  Listing Display table */

 .my-custom-table {
  width: 100%;
 
}

.location_table{  margin: 0 auto;
  margin-top: 20px; width: 95%;}



.my-custom-table td {
  padding: 10px;
  text-align: left;   color: var(--color-secondary-dark-light, #505564);

 
  font-size: 14px;
 
  font-weight: 400;
  
}

.my-custom-table td .icon-Pencil{ margin-right: 8px;}


.my-custom-table td span{ font-size: 20px !important;}

.my-custom-table td:first-child {
  padding-left: 0; 
}

.my-custom-table td:last-child {
  padding-right: 0; 
}


.add-location-link a {
 color: var(--color-primary-blue, #22A9E0) !important;

  
  font-size: 14px; text-decoration: none;

  font-weight: 400;
  line-height: 150%;
 
}


.add-location-link {
 
  margin-top: 15px;
}

.my-custom-table tr td:nth-child(3) {
  color: #787D8C;
}
.search_page_only .left_farea {
  width: 50%;
}
.search_page_only .right_farea{ width: 48%; margin-top: 10px;}


 /*  Listing Display table */



.add-location-link button{ background: none; border: none; color: var(--color-primary-blue, #22A9E0);


  font-size: 14px;
  font-weight: 400;
  line-height: 150%; }

  .left_p{ width: 70%;}
  .right_check {
    width: 25%;
    margin-top: -20px;
}
  .flex_p{ flex-wrap: wrap; justify-content: space-between; margin-top: 15px;}

  .searchresctrivction .rfselect {
    width: 50% !important;
}


.city_state { flex-wrap: wrap; justify-content: space-between;}

.left_cities_enter{ width: 48%;}
.right_state_enter{ width: 48%;}

.city_state label {
  width: 100%;
}


.city_state label {
  width: 100%;
  margin-left: 20px;
}
.right_state_enter label{ margin-left: 0px !important;}

.second_check_fl{ margin-left: 20px;}
.right_checks { margin-left: 30px;     margin-top: -22px}

.d-flex.checkboxes_set.round_checks {
  margin-top: 20px;
}




.contant_location_form .row{ width: 84%;}
.contant_location_form .form-control{ margin-left: 0px; width: 100% !important ;}



  /* checkbox-ui */

  .custom-checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;


    color: var(--color-tertiary-grey, #646978);

    margin-top: 35px;
    
    font-size: 14px;
  
    font-weight: 400;
   


  }
  
  .custom-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: relative;
    height: 20px; 
    width: 20px; 
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid #22A9E0; 
    margin-right: 10px;
  }
  
  .custom-checkbox:checked ~ .checkmark {
    background-color: #22A9E0;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .custom-checkbox:checked ~ .checkmark:after {
    display: block;
  }
  
  .custom-checkbox-container .checkmark:after {
    left: 6px; 
    top: 2px; 
    width: 5px; 
    height: 10px; 
    border: solid white;
    border-width: 0 2px 2px 0; 
    transform: rotate(45deg);
  }
    /* checkbox-ui */



    @media (min-width: 320px) and (max-width:1400px) {

      .amp-pages p {
        font-size: 14px;
       
    }

    }


/* amp-pages */
    @media (min-width: 320px) and (max-width:767px) {
      .custom-checkbox-container {
        
        font-size: 13px;
       
    }
    .mutual_blocks  h2{ font-size: 18px;}
    .title_bar_setting label {
     
      font-size: 14px;
      
  }
  

    }

    /* amp-pages */

       /* ga-extracode */

    @media (min-width: 320px) and (max-width:767px) {

      .amp-pages h3{ font-size: 15px;}
      .ga h3 {
     
        margin-top: 31px;
        
    }

    }


           /* ga-extracode */


                /* metatags */
           @media (min-width: 320px) and (max-width:1279px) {
            .custom_fcontrol{ flex-wrap: wrap;}
            .left_farea {
              width: 100%;
          }
          .right_farea {
          width: 100%;
            font-size: 13px;
            margin-top: 8px;
            margin-left: 5px;
        }
        .jus_set .lfselect .form-select{ width: 100% !important;}
        .search_page_only .left_farea {
          width: 90%;
      }
      .search_page_only .right_farea {
        width: 8%;
       
    }
    .search_page .checkboxes_set { flex-wrap: wrap;}
    .search_page .right_checks.d-flex {
      width: 100%;
      margin-left: 0px;
      margin-top: -1px;
  }
  .left_p {
    width: 100%;
    font-size: 14px;
}
.searchresctrivction .rfselect {
  width: 100% !important;
  margin-top: 11px;
  margin-bottom: 20px;
}
.right_check {
  width: 100%;
  margin-top: -26px;
}
.city_state .left_cities_enter {
  width: 100%;
}
.city_state label{ margin-left: 0px;}
.right_state_enter {
  width: 100%;
}

           }
        
        
             /* metatags */


             /* listing-display */

             @media only screen and (max-width: 1024px) {
              .ldisplay h3{ margin-top: 22px !important;}
              .custom-checkbox-container {
                  font-size: 12px; /* Adjust font size */
                  margin-top: 20px; /* Adjust margin */
                  flex-direction: column; /* Stack label text under the checkbox */
                  align-items: flex-start; /* Align items to the start */
              }
          
              .checkmark {
                  margin-right: 0; /* Remove right margin */
                  margin-bottom: 10px; /* Add some bottom margin to space out from the label */
              }
              .new_badge_list {
                margin-top: 20px;
                margin-bottom: 0px; display: inline-block;
            }
            .new_badge_list input.form-control{ position: static;}
            .new_badge_list input.form-control{ margin-left: 0px;}

            
          }   
          

              /* listing-display */


  /* search */

  @media (min-width: 1280px) and (max-width:1400px) {

    .labelling, .lfselect .labelling{ font-size: 14px !important;}

  }


              @media (min-width: 320px) and (max-width:1279px) {

                .search_page select{ min-width: 100%;}
                .jus_set{ flex-wrap: wrap;}
                .lfselect, .rfselect{ width: 100% !important;}
                .lfselect .labelling {
                  color: var(--color-secondary-dark-mid, #3C4150) !important;
                  font-size: 14px !important;
                  font-weight: 400;
                  margin-top: 29px !important;
                  margin-bottom: 10px;
              }

              }
  /* search */



    /* branding */

    @media (min-width: 1401px) and (max-width:1500px) {
      .round_checks p { font-size: 15px !important; }
      .left_link_comp::after {
        right: 24px;
    }
  }

    @media (min-width: 1280px) and (max-width:1400px) {
      .round_checks p { font-size: 15px !important; }
      .left_link_comp::after {
        right: 24px;
    }
    }

    @media (min-width: 1025px) and (max-width:1279px) { .left_link_comp::after{ display: none;}}

    @media (min-width: 768px) and (max-width:1024px) {
      .left_link_comp::after{ display: none;}

      .branding-page h3 {
       
        margin-top: 30px !important;
    }

 

  .web_logo {
    
  
    
}
.logo_uploader {
  margin-left: 10px !important;
  margin-top: 0px;
}

.logo-uploader {
  margin-top: 40px;
  margin-left: 0px;  flex-wrap: wrap;
}
.search-custom {
  margin-top: 0px !important;
}
.company_links { flex-wrap: wrap;}
.company_links { width: 100%;}
.right_link_comp{width: 100%;}
.left_link_comp{ width: 100%;}
.left_link_comp::after{ display: none;}
.round_checks p{ font-size: 14px !important;}
.company_links .form-control {
 
  margin-left: 0px;
  
}



    }


    @media (min-width: 572px) and (max-width:767px) { 
      .left_link_comp::after{ display: none;}
      .logo_uploader {
        margin-top: 0px !important;
        margin-left: 20px !important;  flex-wrap: wrap;
      }
      .web_logo {
        margin-right: 20px;
    }
    }

    @media (min-width: 320px) and (max-width:767px) {

      .branding-page h3 {
       
        margin-top: 30px !important;
    }

 

  .web_logo {
    
  
    
}
.logo_uploader {
 
  
 
}
.logo_uploader {
  margin-left: 0px !important;
  margin-top: 20px;  
}
.logo-uploader {
  margin-top: 40px;
  margin-left: 0px;  flex-wrap: wrap;
}
.search-custom {
  margin-top: 0px !important;
}
.company_links { flex-wrap: wrap;}
.company_links { width: 100%;}
.right_link_comp{width: 100%;}
.left_link_comp{ width: 100%;}
.left_link_comp::after{ display: none;}
.round_checks p{ font-size: 14px !important;}
.company_links .form-control {
 
  margin-left: 0px;
  
}



    }


      /* branding */
                  